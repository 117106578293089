<template>
  <div class="component-wrap">
    <div class="component-tit">
      <!-- 我的项目 -->
      {{ $t("mine.my_project") }}
    </div>
    <div class="filter-wrap" id="pageTop">
      <!-- 项目类型 -->
      <ListFilter
        v-if="projectType.length > 1"
        only
        :name="$t('mine.project_type')"
        :dataSource="projectType"
        @onChange="switchProjectType"
      >
        <template v-slot:right>
          <div class="search">
            <!-- 请输入名称 -->
            <a-input-search
              v-model:value="name"
              :placeholder="$t('LB_Coach_EnterName')"
              style="width: 220px"
              enter-button
              allow-clear
              @search="onSearch"
            />
          </div>
        </template>
      </ListFilter>
      <!-- 项目状态 -->
      <ListFilter
        only
        :name="$t('Pub_Lab_ProjectStatus')"
        :dataSource="reviewStatus"
        @onChange="switchStatus"
      >
        <template v-slot:right>
          <div class="search" v-if="projectType.length < 2">
            <!-- 请输入名称 -->
            <a-input-search
              v-model:value="name"
              :placeholder="$t('LB_Coach_EnterName')"
              style="width: 220px"
              enter-button
              allow-clear
              @search="onSearch"
            />
          </div>
        </template>
      </ListFilter>
      <!-- 通过状态 -->
      <ListFilter
        only
        :name="$t('CM_PassStatus')"
        :dataSource="passStatus"
        @onChange="switchPassStatus"
        class="filter-lang"
      />
    </div>
    <div class="list-type-wrap">
      <ListType :checked="listType" @change="(type) => (listType = type)" />
    </div>
    <a-spin :spinning="listLoading">
      <div class="content-wrap">
        <template v-if="dataList.length">
          <div
            class="content"
            :class="{ card: listType == 'card', list: listType == 'list' }"
          >
            <div class="item" v-for="(item, index) in dataList" :key="index">
              <ProjectCard
                :timeType="1"
                :newWindow="newWindow == 1"
                :listType="listType"
                :dataSource="item"
              />
            </div>
          </div>
          <div class="page-Wrap">
            <a-pagination
              show-quick-jumper
              hideOnSinglePage
              :defaultPageSize="15"
              v-model:current="currentPage"
              :total="pageTotal"
              @change="pageChange"
            >
              <template #itemRender="{ type, originalElement }">
                <a class="page-a" v-if="type === 'prev'">{{ $t("cm_pre") }}</a>
                <a class="page-a" v-else-if="type === 'next'">{{
                  $t("cm_next")
                }}</a>
                <renderVNode v-else :vnode="originalElement"></renderVNode>
              </template>
            </a-pagination>
          </div>
        </template>
        <a-empty
          v-if="!dataList.length && !listLoading"
          :image="require('@/assets/image/no_data_3.png')"
          :image-style="{ height: '186px' }"
          style="padding: 60px 0"
        >
          <template #description>
            <span style="color: #999">{{ $t("Pub_Lab_NoData") }}</span>
          </template>
        </a-empty>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, toRefs, watch } from "vue";
import { useStore } from "vuex";
import { getMyProjectList } from "@/api/project";
import ListFilter from "@/components/new/filter/ListFilter.vue";
import ListType from "@/components/new/ListType.vue";
import ProjectCard from "@/components/new/mine/ProjectCard.vue";
const renderVNode = (_, { attrs: { vnode } }) => vnode;
export default {
  components: {
    ListFilter,
    ListType,
    ProjectCard,
    renderVNode,
  },
  setup() {
    const { t: $t } = useI18n();
    const store = useStore();
    const state = reactive({
      projectType: [
        // { id: 1, name: $t("MN_TrainingProject_MHome") }, // 培训项目
        // { id: 9, name: $t("cm_newstaff") }, // 新人培训
      ],
      reviewStatus: [
        {
          name: $t("Pub_Tab_NotStart"), // 未开始
          id: 1,
        },
        {
          name: $t("XB_UnderWay"), // 进行中
          id: 2,
        },
        {
          name: $t("XB_HasEnded"), // 已结束
          id: 3,
        },
      ],
      passStatus: [
        { id: 1, name: $t("Pub_Passed") }, // 已通过
        { id: 2, name: $t("CM_NoPass") }, // 未通过
      ],
      dataList: [],
      currentPage: 1,
      pageTotal: 1,
      name: "",
      type: 0,
      status: [],
      qualifier: [],
      listType: "list",
      listLoading: true,
    });

    watch(
      () => store.getters.companyInfo,
      (company) => {
        state.listType = company.useUserCenterShow == 2 ? "card" : "list";
        state.projectType = [];
        if (company.menu.includes('project')) {
          state.projectType.push({ id: 1, name: $t("MN_TrainingProject_MHome") });
          state.type = 1;
        }
        if (company.menu.includes('newstaff')) {
          state.projectType.push({ id: 9, name: $t("cm_newstaff") });
          state.type = 9;
        }
        state.projectType.length > 1 && (state.type = 0);
      },
      { immediate: true }
    );

    // 重新加载list
    const reloadList = () => {
      state.dataList = [];
      state.currentPage = 1;
      getList();
    };

    const pageChange = (n) => {
      state.currentPage = n;
      getList(true);
    };

    const getList = (scroll = false) => {
      state.listLoading = true;
      getMyProjectList({
        page: state.currentPage,
        pageSize: 15,
        name: state.name,
        status: state.status,
        qualifier: state.qualifier,
        taskType: state.type,
      }).then((res) => {
        state.listLoading = false;
        state.pageTotal = res.data.totals;
        state.dataList = res.data.list || [];
        if (scroll) {
          setTimeout(() => {
            document.getElementById("pageTop").scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }, 0);
        }
      });
    };

    const switchStatus = (arr) => {
      state.status = arr[0] ? arr : [];
      reloadList();
    };

    const switchProjectType = (arr) => {
      state.type = arr[0];
      reloadList();
    };

    const switchPassStatus = (arr) => {
      state.qualifier = arr[0] ? arr : [];;
      reloadList();
    };

    const onSearch = () => {
      state.currentPage = 1;
      reloadList();
    };

    getList();

    return {
      newWindow: store.getters.companyInfo.useNewWindow,
      ...toRefs(state),
      switchProjectType,
      switchStatus,
      switchPassStatus,
      pageChange,
      onSearch,
    };
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
