<template>
  <div
    class="data-card"
    :class="{ card: listType == 'card', list: listType == 'list' }"
    @click="jump"
  >
    <div class="tag">
      <span class="normal" v-if="dataSource.taskType === 9">
        <!-- 新人培训 -->
        {{ $t('cm_newstaff') }}
      </span>
      <span class="normal" v-else>
        <!-- 培训项目 -->
        {{ $t("MN_TrainingProject_MHome") }}
      </span>
    </div>
    <div class="cover">
      <img :src="dataSource.coverImg" alt="cover" />
      <div class="bottom-mask">
        <img
          class="icon"
          src="@/assets/image/mine/hourglass.png"
          alt="time"
        />
        <span v-if="dataSource.endTime < currentTime">已结束</span>
        <span v-else-if="dataSource.startTime > currentTime">
          <!-- 未开始 -->
          {{ $t('Pub_Tab_NotStart') }}
        </span>
        <span v-else>
          <!-- 剩余 -->
          {{ $t('remainder') }}: {{ timeRmaining(dataSource.endTime) }}
        </span>
      </div>
    </div>
    <div class="detail">
      <h5>
        <span class="status bg-green" v-if="dataSource.qualifier == 1">
          <!-- 已通过 -->
          {{ $t("Pub_Passed") }}
        </span>
        <span class="status bg-red" v-else-if="dataSource.qualifier == 2">
          <!-- 未通过 -->
          {{ $t("CM_NoPass") }}
        </span>
        <span class="status bg-normal" v-else>
          <!-- 未参与 -->
          {{ $t("CM_NoTakePartIn") }}
        </span>
        <a-popover trigger="hover" placement="topLeft">
          <template #content>
            <div class="popover-content">
              {{ dataSource.taskName }}
            </div>
          </template>
          {{ dataSource.taskName }}
        </a-popover>
      </h5>
      <div class="row">
        <!-- 时间 -->
        <span v-show="listType == 'list'">{{ $t("Pub_Tab_Time") }}：</span
        ><img
          class="icon"
          v-show="listType == 'card'"
          src="@/assets/image/mine/time.png"
          alt="time"
        /><span class="fs12"
          >{{ dateFormat(dataSource.startTime) }} ～
          {{ dateFormat(dataSource.endTime) }}</span
        >
      </div>
      <div class="row" v-show="listType == 'list'">
        <!-- 学习人数 -->
        {{ $t("XB_NumberOfLearners") }}：{{ dataSource.learnUsers }}
      </div>
      <div class="rate mt12">
        <!-- 总进度 -->
        <span v-show="listType == 'list'">{{ $t("course.total_progress") }}：</span>
        <a-progress :percent="dataSource.progress" strokeColor="#468CE3" size="small">
          <template #format>
            <div class="rate-format">{{ dataSource.progress }}%</div>
          </template>
        </a-progress>
      </div>
    </div>
    <div class="btns" v-show="listType == 'list'">
      <div class="btn">
        {{ $t("view_detail") }}
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { dateFormat, timeRmaining } from "@/utils/tools";
import OpenData from "@/components/OpenData.vue";
export default {
  name: "ProjectCard",
  components: {
    OpenData,
  },
  props: {
    newWindow: {
      type: Boolean,
      default: false,
    },
    listType: {
      type: String,
      default: "card",
    },
    dataSource: {
      type: Object,
      default: {},
    },
    timeType: {
      type: Number,
      default: 1,
    },
  },
  setup(props) {
    const router = useRouter();
    const urlType = {
      1: 'project',
      9: 'newstaff',
      14: 'project',
    };

    const jump = () => {
      const str = urlType[props.dataSource.taskType];
      if (props.newWindow) {
        window.open(
          `/${str}/detail?ddtab=true&id=${props.dataSource.taskId}`
        );
      } else {
        router.push({
          path: `/${str}/detail`,
          query: {
            id: props.dataSource.taskId,
          },
        });
      }
    };

    return {
      dateFormat,
      timeRmaining,
      currentTime: Date.parse(new Date()) / 1000,
      jump,
    };
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
